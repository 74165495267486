<template>
  <v-layout class="">
    <v-row dense v-if="items && items.length > 0" class="d-flex justify-center">
      <v-col cols="12" lg="8">
        <v-card>
          <v-card-title class="display-2">
            <v-btn small @click.stop="$router.push({ name: 'home' })" icon>
              <v-icon left>mdi-arrow-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            {{ $lang("Quotes") }}
          </v-card-title>
          <v-divider></v-divider>
        </v-card>
      </v-col>
      <v-col v-for="(item, i) in items" :key="i" cols="12" lg="8" >
        <v-card
          style="overflow: hidden"
          @click="$router.push({ name: 'quote', params: { id: item.id } })"
        >
          <v-card-title class="title">
            {{
              $store.getters.getCurrLocale == "hi"
                ? item.quote_hi
                  ? item.quote_hi
                  : item.quote
                : item.quote
            }}
          </v-card-title>
          <v-card-text class="d-flex justify-content">
            <v-avatar v-if="item.author_image" size="30" class="mr-2">
              <v-img :src="item.author_image"></v-img>
            </v-avatar>
            <div class="mt-1 subtitle-1 font-weight-light">
              {{
                $store.getters.getCurrLocale == "hi"
                  ? item.author_hi
                    ? item.author_hi
                    : item.author
                  : item.author
              }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" class="justify-center text-center">
        {{ $lang("NOITEMS") }}
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";
export default {
  name: "quotes",
  components: {},
  data() {
    return {
      items: [],
    };
  },
  computed: {},
  methods: {
    fetchItems() {
      bus.$emit("showWait");
      axios
        .get("quotes/public")
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    this.fetchItems();
  },
};
</script>

